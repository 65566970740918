import React from "react";
import { Link } from "gatsby"
import Logo from '../assets/logo.svg'
import { AiOutlineMail } from "react-icons/ai"
import { AiOutlinePhone } from "react-icons/ai"




const Footer = ({ navigation, companyContact, footerInfo }) => {
    const { title, secondaryTitle, subtitle } = footerInfo
    const { phoneNumber, companyEmail } = companyContact
    return (
        <div className="container bg-gray-light p-8 min-w-full">
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                <div>
                    <img className="w-8 md:w-20" src={Logo} alt="VM Construction's Logo"></img>
                    <div className="font-rubik font-light pt-6 text-3xl">
                        {title}
                    </div>
                    <div className="text-gray font-semibold pt-2">
                        {secondaryTitle} <span className="text-purple"><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></span>
                    </div>
                    <div className="text-sm font-medium pt-2">{subtitle}</div>
                </div>
                <div className="pt-10 md:pt-0">
                    <div className="font-rubik font-semibold">
                        Navigation
                    </div>
                    <ul>
                        {navigation.map((link, index) => {
                            return (
                                <li key={index} className="capitalize text-gray hover:underline pt-2"><Link to={link.link}>{link.name}</Link></li>
                            )
                        })}
                    </ul>
                </div>
                <div>
                    <div className="font-rubik font-semibold pt-10 lg:py-0">
                        Contact
                    </div>
                    <div className="pt-2">
                        <span className="flex items-center">
                            <AiOutlineMail className="text-purple" />
                            <span className="pl-2 font-rubik"> <a href={`mailto:${companyEmail}`} className="">{companyEmail}</a></span>
                        </span>
                        <span></span>
                        <span className="flex items-center">
                            <AiOutlinePhone className="text-gray" />
                            <span className="pl-2 font-rubik"><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></span>
                        </span>

                    </div>
                </div>


            </div>
            <div className="flex justify-between pt-10">
                <div className="text-xs">© Copyright {new Date().getFullYear()}, VM Construction. All rights reserved.</div>
                <div className="text-xs hover:underline text-gray-lighter"><Link to="/privacypolicy">Privacy Policy</Link></div>
                <div className="text-xs hover:underline text-gray-lighter">Website by <a href="https://www.redboltstudio.com">Red Bolt Studio</a></div>
            </div>

        </div>
    );
}

export default Footer