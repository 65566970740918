import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import CookieConsent from "react-cookie-consent";
import Navbar from './Navbar'
import Footer from './Footer'
import TopContactBanner from './TopContactBanner'



const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
  query footer {
    contentfulFooter {
      title
      secondaryTitle
      subtitle
    }
    contentfulCompanyInfo {
      companyEmail
      phoneNumber
    }
    site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
  }
  `)

  const navigationLinks = data.site.siteMetadata.menuLinks
  const companyInfo = data.contentfulCompanyInfo
  const footerInfo = data.contentfulFooter


  return (
    <>
      {/* <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent> */}
      <TopContactBanner contact={companyInfo} />
      <Navbar />
      <div className="flex flex-col min-h-screen bg-gray-200 flex-grow">
        {children}
      </div>
      <Footer navigation={navigationLinks} companyContact={companyInfo} footerInfo={footerInfo} />
    </>
  )
}

export default Layout
