import React from "react";
import { AiOutlineMail } from "react-icons/ai"
import { AiOutlinePhone } from "react-icons/ai"

const TopContactBanner = ({ contact }) => {
    const { phoneNumber, companyEmail } = contact

    return (
        <div className="h-10 sticky top-0 z-50 bg-gray-light shadow-md border border-gray-dark flex justify-end items-center">
            <div className="flex items-center justify-end pr-10 sm:pr-20">
                <div className="flex items-center">
                <span className="text-xs text-purple font-rubik pr-1">24/7 - Call Now</span>
                    <AiOutlinePhone className="text-purple" />
                    <span className="pl-2 font-rubik text-sm font-semibold text-gray-dark"><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></span>
                </div>
                <div className="flex items-center pl-4">
                    <AiOutlineMail className="text-purple" />
                    <span className="pl-2 font-rubik text-sm text-gray-dark"> <a href={`mailto:${companyEmail}`} className="">Email</a></span>
                </div>

            </div>
        </div>
    );
}

export default TopContactBanner