import React, { useState } from 'react'


import Logo from '../assets/logo.svg'

import { graphql, Link, useStaticQuery } from "gatsby"

import MenuMobile from "./MenuMobile"
import { FaBars } from "react-icons/fa"


const Navbar = () => {



  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  return (

    <div className="container absolute z-40 pt-6 pb-12 md:pt-12 px-4 sm:pl-10 sm:pr-4">
      <div className="flex justify-between items-center font-rubik">
        <Link to="/">
          <img alt="VM Construction" className="w-8 md:w-20 bg-gray-light rounded py-1 px-2" src={Logo}></img>

        </Link>

        <button
          className="sm:hidden"
          onClick={() => setIsMenuOpen(true)}
          aria-label="Open Menu"
        >
          <FaBars className="h-6 w-auto text-white fill-current -mt-1" />
        </button>

        <div className="hidden sm:block">
          {site.siteMetadata.menuLinks.map((link, key) => (
            <Link
              key={`menu_desktop_link${key}`}
              className="ml-6 sm:ml-8 text-sm sm:text-base  font-medium px-px border-b-2 pb-2 border-transparent text-white hover:text-gray hover:border-gray-lightest transition duration-150 ease-in-out"
              activeClassName="border-gray-dark text-gray hover:border-gray-light"
              to={link.link}
            >
              {link.name}
            </Link>
          ))}
        </div>
      </div>
      <MenuMobile
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        links={site.siteMetadata.menuLinks}
      />
    </div>
  )
}

export default Navbar
